import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MealStopwatch from "../components/MealStopwatch";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';


const useStyles = makeStyles(theme => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 1)'
  }
}));

export default function AssetMeal(props) {

  const classes = useStyles();
  const content = props.assetList.map((key) =>
    <Grid key={props.assetList.indexOf(key)} item xs={3} sm={3}>
      <Card className={classes.card}>
        <Typography color="primary" align="center" variant={"h3"}
          key={props.assetList.indexOf(key)}
          value={key.assetId}
        > {key.assetName} 
        </Typography>
        
        { key.tracking === "Meal" ? 
        <MealStopwatch 
          time={2400 - ((new Date() - new Date(key.updatedTime)) / 1000)}
          setPlay={{ state: false, data: '' }}
          assetId={key.assetName}
        /> : <Typography align="center" variant={"h5"}> --:--</Typography> 
        }
      </Card>
      <audio id={key.assetName}>
        <source src={"/soundclips/" + key.assetName + ".mp3"}></source>
      </audio>
    </Grid>

  );
  return (
    <Grid>
      <Grid container justify="flex-start" alignItems="center" spacing={2}>{content}</Grid>
    </Grid>
  );
}